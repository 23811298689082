.create_border {
  width: 392px;
  
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffc5d6;
  opacity: 1;

  @media screen and (min-width: 361px) and (max-width:425px) {
   
     width:350px !important;
  }
   @media screen and (min-width: 300px) and (max-width:360px) {
   
     width:315px !important;
  }
}
.continue_button {
  width: 184px;
  height: 38px;
}
.sign_in {
  width: 100%;
  height: 43px;
  outline: none;
  border: 0.699999988079071px solid #757575;
  border-radius: 5px;
  opacity: 1;
}
.mobile_default {
  outline: none;
}
.icons_google {
  width: 30px;
  height: 30px;
}
.icons_facebook {
  width: 26px;
  height: 26px;
}
