.create_border {
    width: 420px;
    
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ffc5d6;
    opacity: 1;
  
    @media screen and (min-width: 361px) and (max-width:425px) {
     
       width:350px !important;
    }
     @media screen and (min-width: 300px) and (max-width:360px) {
     
       width:315px !important;
    }
  }
  .continue_button {
    padding: 7px 53px;
    width: 178px;
    height: 38px;
  }